export function remove(node) {
    const last = node.last;
    const next = node.next;
    last.next = next;
    next.last = last;
}
export function isListItem(node) {
    return Boolean(node.next) && Boolean(node.last);
}
export class List {
    constructor() {
        this.head = {};
        this.tail = {};
        this.head.next = this.tail;
        this.tail.last = this.head;
    }
    get first() {
        if (isListItem(this.head.next)) {
            return this.head.next;
        }
    }
    get last() {
        if (isListItem(this.tail.last)) {
            return this.tail.last;
        }
    }
    push(item) {
        const tail = this.tail;
        const node = {
            value: item,
            next: undefined,
            last: undefined,
        };
        node.last = tail.last;
        tail.last.next = node;
        node.next = tail;
        tail.last = node;
        return node;
    }
    pop() {
        const tail = this.tail;
        const head = this.head;
        const last = tail.last;
        if (isListItem(last)) {
            const nextLast = last.last;
            tail.last = nextLast;
            nextLast.next = tail;
            return last;
        }
        else {
            head.next = tail;
            tail.last = head;
            return undefined;
        }
    }
    forEach(callback) {
        let node = this.head.next;
        while (isListItem(node)) {
            if (node.value) {
                callback(node.value);
            }
            node = node.next;
        }
    }
    size() {
        let i = 0;
        this.forEach(() => i++);
        return i;
    }
}
