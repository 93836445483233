export function Delayer() {
    let time = -1;
    return {
        delay(fn, timeout = 10) {
            if (time !== -1) {
                clearTimeout(time);
            }
            time = setTimeout(() => {
                fn();
                time = -1;
            }, timeout);
        }
    };
}
